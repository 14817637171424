import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartPublicCompositeLegacy } from '@centrito/api/nest/shopper/cart/domain/composites'
import type { CouponStatusChange } from '@centrito/api/nest/shopper/cart/domain/types/status-change'
import type { UpdateCartItemsPayload } from '@centrito/api/router/user/cart/computeCartItemPricing'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export interface UpdateCartItemsResponse {
  newCart: CartPublicCompositeLegacy
  couponStatusChange: CouponStatusChange
  cartValidationError?: string
}

const captureShopperUpdateCartItemsEvent = ({
  quantityChange,
  phoneNumber,
}: {
  quantityChange: number
  phoneNumber?: string
}): void => {
  if (!isNil(phoneNumber)) {
    metaClient.captureCustomEvent('shopper_update_cart_items', { ph: phoneNumber })
  } else {
    metaClient.captureCustomEvent('shopper_update_cart_items')
  }
  posthogClient.captureCustomEvent('shopper_update_cart_items', { quantityChange })
}

const updateCartItem = createAsyncThunk<
  UpdateCartItemsResponse,
  UpdateCartItemsPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartItem`, async (payload, thunkAPI) => {
  try {
    const { product, productVariant, productPresentation, quantityChange, pricingData } = payload
    const targetItem = {
      productId: product.product.id,
      productVariantId: productVariant.variant.id,
      sizeName: productPresentation.name,
      priceDiscountItem: pricingData.priceItemsDiscount,
    }
    const currentState = thunkAPI.getState()
    const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

    captureShopperUpdateCartItemsEvent({
      quantityChange,
      phoneNumber: currentState.auth.authenticatedData?.phone,
    })

    const { newCart, couponStatusChange, cartValidationError } =
      await trpcProxyClient.user.cart.updateCartItem.mutate({
        cartOwner,
        targetItem,
        quantityChange,
      })
    return {
      newCart,
      couponStatusChange,
      cartValidationError,
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartItem
