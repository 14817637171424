import { createAsyncThunk } from '@reduxjs/toolkit'
import type { CartPublicCompositeLegacy } from '@centrito/api/nest/shopper/cart/domain/composites'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import { type AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

const removeCouponWarning = createAsyncThunk<
  {
    newCart: CartPublicCompositeLegacy
  },
  undefined,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/removeCouponWarning`, async (_payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
  const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)

  return { newCart }
})
export default removeCouponWarning
