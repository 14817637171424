import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import { $Enums } from '@centrito/db'
import type { CartPublicCompositeLegacy } from '@centrito/api/nest/shopper/cart/domain/composites'
import type { UpdateCartPaymentDataInput } from '@centrito/api/nest/shopper/cart/domain/schemas'
import type { CouponStatusChange } from '@centrito/api/nest/shopper/cart/domain/types/status-change'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

interface UpdateCartPaymentDataPayload {
  input: UpdateCartPaymentDataInput
}

export interface UpdateCartPaymentDataResponse {
  method: $Enums.OrderPaymentMethod
  newCart?: CartPublicCompositeLegacy
  couponStatusChange: CouponStatusChange
}

const updateCartPaymentData = createAsyncThunk<
  UpdateCartPaymentDataResponse,
  UpdateCartPaymentDataPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartPaymentData`, async (payload, thunkAPI) => {
  try {
    const { method } = payload.input

    switch (method) {
      case $Enums.OrderPaymentMethod.PALOMMA__PSE: {
        const { newCart, couponStatusChange } =
          await trpcProxyClient.user.cart.updateCartPaymentData.mutate({
            method,
            bank: payload.input.bank,
          })
        return {
          method,
          newCart,
          couponStatusChange,
        }
      }
      case $Enums.OrderPaymentMethod.USER_CREDITS:
        return {
          method,
          amount: payload.input.amount,
          couponStatusChange: { hasChangeStatus: false },
        }
      case $Enums.OrderPaymentMethod.CASH: {
        const { newCart, couponStatusChange } =
          await trpcProxyClient.user.cart.updateCartPaymentData.mutate({
            method,
          })
        return {
          method,
          newCart,
          couponStatusChange,
        }
      }
      default:
        return thunkAPI.rejectWithValue(
          miniSerializeError(new Error(`Unhandled payment method: ${method}`)),
        )
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartPaymentData
