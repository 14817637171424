import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { CartPublicCompositeLegacy } from '@centrito/api/nest/shopper/cart/domain/composites'
import type { CouponStatusChange } from '@centrito/api/nest/shopper/cart/domain/types/status-change'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface LoadAnonymousCartResponse {
  cart: CartPublicCompositeLegacy
  couponStatusChange: CouponStatusChange
}

const loadAnonymousCart = createAsyncThunk<LoadAnonymousCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/loadAnonymousCart`,
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const anonymousCartId = currentState.userData.anonymousCartId
      const { newCart, couponStatusChange } =
        await trpcProxyClient.user.cart.replaceAnonymousCart.mutate({
          anonymousCartId: anonymousCartId!,
        })
      return {
        cart: newCart,
        couponStatusChange,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default loadAnonymousCart
