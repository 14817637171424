import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import * as authThunks from '@centrito/app/store/slices/auth/thunks'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import type {
  CheckoutPayloadWithPartialSteps,
  UserDataState,
} from '@centrito/app/store/slices/userData/types'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import initialState from './initialState'
import * as thunks from './thunks'

const REDUCER_NAME = REDUCER_NAMES.USER_DATA
const MAX_SESSION_PRODUCTS = 20

const userDataSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setClientUserId(state, { payload }: PayloadAction<{ userId: string | undefined }>) {
      return {
        ...state,
        salesAssistant: {
          clientUserId: payload.userId ?? null,
        },
      }
    },
    setCheckoutState(state, { payload }: PayloadAction<Partial<CheckoutPayloadWithPartialSteps>>) {
      if (!isNil(payload.paymentStepState) && !isNil(payload.paymentStepState.selectedMethod)) {
        posthogClient.captureCustomEvent('shopper_checkout_payment_selected', {
          paymentMethod: payload.paymentStepState.selectedMethod,
          paymentBank: payload.paymentStepState.selectedBank,
        })
      }

      return {
        ...state,
        checkout: {
          ...state.checkout,
          isActionEnabled: payload.isActionEnabled ?? state.checkout.isActionEnabled,
          deliveryStepState: {
            ...state.checkout.deliveryStepState,
            ...payload.deliveryStepState,
          },
          paymentStepState: {
            ...state.checkout.paymentStepState,
            ...payload.paymentStepState,
          },
        },
      }
    },
    setSearchedTerms(state, { payload }: PayloadAction<{ searchedTerm: string }>) {
      const { searchedTerm } = payload
      const { searchedTerms } = state
      const termIndex = searchedTerms.indexOf(searchedTerm)

      if (termIndex !== -1) {
        searchedTerms.splice(termIndex, 1)
      }

      if (searchedTerms.length >= 20) {
        searchedTerms.pop()
      }

      searchedTerms.unshift(searchedTerm)
    },
    emptySearchedTerms(state) {
      return {
        ...state,
        searchedTerms: initialState.searchedTerms,
      }
    },
    removeSearchedTerm(state, { payload }: PayloadAction<{ termIndex: number }>) {
      const { termIndex } = payload
      const { searchedTerms } = state
      if (termIndex !== -1) {
        const updatedTerms = [
          ...searchedTerms.slice(0, termIndex),
          ...searchedTerms.slice(termIndex + 1),
        ]
        state.searchedTerms = updatedTerms
      }
    },
    emptyCart(state) {
      return {
        ...state,
        cart: initialState.cart,
      }
    },
    setAppLastOpenedAt(state) {
      return {
        ...state,
        appLastOpenedAt: new Date().toISOString(), // Set app start time to current time
      }
    },
    setScrollingToTop(state, { payload }: PayloadAction<{ isScrollingToTop: boolean }>) {
      return {
        ...state,
        isScrollingToTop: payload.isScrollingToTop,
      }
    },
    setAnonymousUserId(state, { payload }: PayloadAction<{ anonymousUserId: string }>) {
      return {
        ...state,
        anonymousUserId: payload.anonymousUserId,
      }
    },
    setAnonymousCartId(state, { payload }: PayloadAction<{ anonymousCartId: string }>) {
      return {
        ...state,
        anonymousCartId: payload.anonymousCartId,
      }
    },
    setSessionProductIds(state, { payload }: PayloadAction<{ productId: string }>) {
      const { productId } = payload

      // Remove the product ID if it already exists
      const newSessionProductIds = state.sessionProductIds.filter((id: string) => id !== productId)

      // Add the new (or updated) entry to the beginning of the array
      newSessionProductIds.unshift(productId)

      // If the array exceeds the maximum size, remove the oldest entry
      if (state.sessionProductIds.length >= MAX_SESSION_PRODUCTS) {
        newSessionProductIds.pop()
      }

      return {
        ...state,
        sessionProductIds: newSessionProductIds,
      }
    },
    setIsEmailNotificationsEnabled(state, { payload }: PayloadAction<{ isEnabled: boolean }>) {
      return {
        ...state,
        isEmailNotificationsEnabled: payload.isEnabled,
      }
    },
    clearSessionProductIds(state) {
      return {
        ...state,
        sessionProductIds: [],
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(
    //   HYDRATE,
    //   (state): UserDataState => ({
    //     // No need to pass SSR userData state to client
    //     ...state,
    //   }),
    // )
    builder.addCase(authThunks.loadAuthStateChange.rejected, (state): UserDataState => {
      // Validate for null values before proceeding
      if (isNil(state.cart) || isNil(state.cart.items) || isNil(state.cart.pricingData)) {
        console.error('Invalid state: cart, items or pricingData is null')
        return state
      }

      return {
        ...initialState,
        cart: {
          ...initialState.cart,
          cart: initialState.cart?.cart,
          items: state.cart.items,
          pricingData: state.cart.pricingData,
        },
        searchedTerms: state.searchedTerms,
        anonymousCartId: state.anonymousCartId,
        anonymousUserId: state.anonymousUserId,
      }
    })
    builder.addCase(thunks.updateCartItem.fulfilled, (state, { payload }): UserDataState => {
      // Validate for null values before proceeding
      if (isNil(payload.newCart) || isNil(payload.newCart.items)) {
        console.error('Invalid payload: updatedCart or items is null')
        return state
      }

      const resetPaymentSelection =
        !payload.newCart.cart.validPaymentMethods?.methods.includes('CASH') &&
        payload.newCart.cart.paymentData?.method === 'CASH'
          ? {
              selectedMethod: null,
              selectedBankName: null,
              selectedBank: null,
            }
          : {}

      return {
        ...state,
        cart: payload.newCart,
        checkout: {
          ...state.checkout,
          paymentStepState: {
            ...state.checkout.paymentStepState,
            ...resetPaymentSelection,
          },
        },
      }
    })

    builder.addCase(thunks.updateUserAddress.fulfilled, (state, { payload }): UserDataState => {
      const modified = { ...state }

      if (!isNil(state.userProfile)) {
        modified.userProfile = {
          ...state.userProfile,
          lastAlliedStoreId: payload.alliedStore?.id ?? state.userProfile.lastAlliedStoreId,
        }
      }

      // Validate for null values before proceeding
      if (
        isNil(payload.newCart) ||
        isNil(payload.newCart.items) ||
        isNil(payload.newCart.pricingData)
      ) {
        console.error('Invalid payload: newCart, items or pricingData is null')
        return state
      }

      modified.cart = payload.newCart

      if (!isNil(payload.alliedStore)) {
        modified.selectedStore = payload.alliedStore
      }

      const resetPaymentSelection =
        !payload.newCart.cart.validPaymentMethods?.methods.includes('CASH') &&
        payload.newCart.cart.paymentData?.method === 'CASH'
          ? {
              selectedMethod: null,
              selectedBankName: null,
              selectedBank: null,
            }
          : {}

      return {
        ...modified,
        checkout: {
          ...modified.checkout,
          deliveryStepState: {
            ...modified.checkout.deliveryStepState,
            selectedType: payload.type,
          },
          paymentStepState: {
            ...modified.checkout.paymentStepState,
            ...resetPaymentSelection,
          },
        },
      }
    })
    builder.addCase(thunks.updateCartPaymentData.fulfilled, (state, { payload }): UserDataState => {
      return {
        ...state,
        cart: {
          ...state.cart,
          ...payload.newCart,
        },
      }
    })
    builder.addCase(thunks.createOrder.fulfilled, (state): UserDataState => {
      // Validate for null values before proceeding
      if (isNil(state.cart) || isNil(state.cart.items) || isNil(state.cart.pricingData)) {
        console.error('Invalid state: cart, items or pricingData is null')
        return state
      }

      return {
        ...state,
        cart: initialState.cart,
        checkout: initialState.checkout,
      }
    })
    builder.addCase(
      thunks.createOrder.rejected,
      (state): UserDataState => ({
        ...state,
        checkout: initialState.checkout,
      }),
    )
    builder.addCase(
      thunks.addCoupon.fulfilled,
      (state, { payload }): UserDataState => ({
        ...state,
        cart: payload.newCart,
      }),
    )
    builder.addCase(
      thunks.removeCoupon.fulfilled,
      (state, { payload }): UserDataState => ({
        ...state,
        cart: payload.newCart,
      }),
    )
    builder.addCase(
      thunks.removeCouponWarning.fulfilled,
      (state, { payload }): UserDataState => ({
        ...state,
        cart: payload.newCart,
      }),
    )
    builder.addCase(thunks.revalidateCoupon.fulfilled, (state, { payload }): UserDataState => {
      return {
        ...state,
        cart: payload.newCart,
      }
    })
    builder.addCase(
      thunks.updateUserGovernmentId.fulfilled,
      (state, { payload }): UserDataState => ({
        ...state,
        userProfile: {
          ...payload.newUserInfo,
          dateOfBirth: payload.newUserInfo.dateOfBirth
            ? new Date(payload.newUserInfo.dateOfBirth)
            : null,
          governmentId: payload.newUserInfo.governmentId,
          governmentIdType: payload.newUserInfo.governmentIdType,
          email: payload.newUserInfo.email ?? state.userProfile?.email ?? null,
        },
      }),
    )
    builder.addCase(authThunks.signUp.fulfilled, (state, { payload }): UserDataState => {
      return {
        ...state,
        userProfile: payload.userProfile,
      }
    })
    builder.addCase(
      authThunks.signOut.fulfilled,
      (state): UserDataState => ({
        ...state,
        userProfile: null,
      }),
    )
    builder.addCase(
      authThunks.signOut.rejected,
      (state): UserDataState => ({
        ...state,
        userProfile: null,
      }),
    )
    builder.addCase(
      thunks.getUserCart.fulfilled,
      (state, { payload }): UserDataState => ({
        ...state,
        cart: payload.newCart,
      }),
    )
    builder.addCase(thunks.loadAnonymousCart.fulfilled, (state, { payload }): UserDataState => {
      return {
        ...state,
        cart: {
          ...state.cart,
          ...payload.cart,
        },
      }
    })
    builder.addCase(thunks.loadUserData.fulfilled, (state, { payload }): UserDataState => {
      return {
        ...state,
        ...payload,
      }
    })
  },
})

export const actions = userDataSlice.actions
export default userDataSlice
