import isNil from 'lodash.isnil'
import omit from 'lodash.omit'
import pick from 'lodash.pick'
// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import type { z } from 'zod'
import { $Enums } from '@centrito/db'
import initialState from '@centrito/app/store/slices/userData/initialState'
import { UserDataState } from '@centrito/app/store/slices/userData/types'

export const migrateState = (state: any): any => {
  // Adjust the state structure from version -1 to version 0
  if (isNil(state)) {
    return state
  }

  // CONTEXT: remove legacy cart and products
  if (state._persist.version === -1) {
    return {
      ...state,
      userData: {
        ...state.userData,
        userProfile: state.userData?.userProfile || null,
        categoryIndex: state.userData?.categoryIndex || 0,
        cart: {
          items:
            state.userData.cart.items?.map((item: any) => ({
              product: {
                ...pick(item.product, ['category', 'merchant', 'highestCampaign']),
                variants: item.product.variants.map((variant: any) => ({
                  images: variant.images,
                  variant: omit(variant, ['images']),
                })),
                product: {
                  ...omit(item.product, ['category', 'merchant', 'highestCampaign', 'variants']),
                },
              },
              productVariant: {
                images: item.productVariant.images,
                variant: omit(item.productVariant, ['images']),
              },
              cartItem: { id: uuidv4(), ...omit(item, ['product', 'productVariant']) },
            })) || [],
          deliveryData: state.userData.cart.deliveryData || null,
          deliveryDate: state.userData.cart.deliveryDate || null,
          discountData: state.userData.cart.discountData || null,
          ongoingPaymentRequestId: state.userData.cart.ongoingPaymentRequestId || null,
          pricingData: {
            itemsCost: 0,
            itemsRevenue: 0,
            itemsTax: 0,
            subtotal: 0,
            shippingRevenue: 0,
            itemsDiscount: 0,
            cartDiscount: 0,
            discount: 0,
            total: 0,
            ...state.userData.cart.pricingData,
          },
          validPaymentMethods: state.userData.cart.validPaymentMethods || {
            methods: [
              $Enums.OrderPaymentMethod.CASH,
              $Enums.OrderPaymentMethod.PALOMMA__PSE,
              $Enums.OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
              $Enums.OrderPaymentMethod.PALOMMA__CARD,
            ],
          },
          deliveryType: state.userData.cart.deliveryType || null,
        },
      },
    }
  }
  // CONTEXT: Make cart pricing data never null
  if (state._persist.version === 0) {
    return {
      ...state,
      userData: {
        ...state.userData,
        cart: {
          ...state.userData.cart,
          pricingData: {
            itemsCost: 0,
            itemsRevenue: 0,
            itemsTax: 0,
            subtotal: 0,
            shippingRevenue: 0,
            itemsDiscount: 0,
            cartDiscount: 0,
            discount: 0,
            total: 0,
            ...state.userData.cart.pricingData,
          },
        },
      },
    }
  }

  if (state._persist.version === 1) {
    return {
      ...state,
      userData: {
        ...state.userData,
        anonymousCartId: isNil(state.userData.anonymousCartId)
          ? uuidv4()
          : state.userData.anonymousCartId,
        cart: {
          ...state.userData.cart,
          cart: {
            ...state.userData.cart.cart,
          },
        },
      },
    }
  }

  // Safe parsing of the state to ensure it conforms to the expected schema
  const parsedState = (
    Object.keys(UserDataState.shape) as Array<keyof z.infer<typeof UserDataState>>
  ).reduce(
    (acc, key) => {
      const subSchema = UserDataState.shape[key]
      const subParsed = subSchema.safeParse(state.userData[key])

      // Use the parsed data if successful, otherwise fallback to initialState
      acc[key] = subParsed.success ? subParsed.data : initialState[key]

      return acc
    },
    {} as z.infer<typeof UserDataState>,
  )

  return {
    ...state,
    userData: parsedState,
  }
}
