import { httpBatchLink } from '@trpc/client'
import { createTRPCClient } from '@trpc/react-query'
import type { AppRouter } from '@centrito/api'
import { transformer } from '@centrito/api/transformer'
import { ROOT_URL_API } from '@centrito/app/config'

export const trpcClientConfig = {
  links: [
    httpBatchLink({
      url: `${ROOT_URL_API}/trpc`,
      transformer,
    }),
  ],
}

const proxyClient: ReturnType<typeof createTRPCClient<AppRouter>> =
  createTRPCClient<AppRouter>(trpcClientConfig)

export default proxyClient
