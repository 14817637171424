import { OrderDeliveryDestinationType, OrderPaymentMethod } from '@prisma/client/react-native'
import { type Equals, assert } from 'tsafe'
import { z } from 'zod'
import { PalommaBank } from '@centrito/api/nest/operations/payments/palomma/domain/enums'
import type {
  AlliedStorePublic,
  UserProfilePublic,
} from '@centrito/api/nest/platform/database/domain'
import type { CartPublicCompositeLegacy } from '@centrito/api/nest/shopper/cart/domain/composites'

/* --- STATE --- */
export const CheckoutStateSchema = z.object({
  isActionEnabled: z.boolean(),
  deliveryStepState: z.object({
    selectedType: z.nativeEnum(OrderDeliveryDestinationType).nullable(),
  }),
  paymentStepState: z.object({
    selectedMethod: z.nativeEnum(OrderPaymentMethod).nullable(),
    selectedBank: PalommaBank.nullable(),
    selectedBankName: z.string().nullable(),
  }),
})

export type CheckoutState = z.infer<typeof CheckoutStateSchema>

export const CheckoutPayloadWithPartialStepsSchema = CheckoutStateSchema.partial()

export type CheckoutPayloadWithPartialSteps = z.infer<typeof CheckoutPayloadWithPartialStepsSchema>

type DbState = {
  userProfile: UserProfilePublic | null
  cart: CartPublicCompositeLegacy
  selectedStore: AlliedStorePublic | null
}

export type AppDataState = Omit<DbState, keyof DbState> & {
  checkout: CheckoutState
  searchedTerms: string[]
  anonymousUserId: string | null
  sessionProductIds: string[]
  isEmailNotificationsEnabled: boolean
}

export const UserDataState = z.object({
  // Db state
  userProfile: z.any(),
  cart: z.any(),
  selectedStore: z.any(),
  // App state
  checkout: CheckoutStateSchema,
  searchedTerms: z.array(z.string()),
  anonymousUserId: z.string().nullable(),
  sessionProductIds: z.array(z.string()),
  isEmailNotificationsEnabled: z.boolean(),
})

export type UserDataState = {
  userProfile: UserProfilePublic | null
  cart: CartPublicCompositeLegacy
  selectedStore: AlliedStorePublic | null
  checkout: CheckoutState
  searchedTerms: string[]
  anonymousCartId: string | null
  anonymousUserId: string | null
  sessionProductIds: string[]
  isEmailNotificationsEnabled: boolean
}

assert<
  Equals<
    Omit<AppDataState, 'userProfile' | 'cart' | 'selectedStore'>,
    Omit<z.infer<typeof UserDataState>, 'userProfile' | 'cart' | 'selectedStore'>
  >
>()
